import React from 'react';
import Video from '../videos/hero-video.mp4';

const Hero = () => {
   return (
      <div className='relative flex h-[650px] max-h-[85vh] w-full items-center justify-center overflow-hidden bg-gray-200 object-center shadow-lg'>
         <video
            className='h-full w-full object-cover'
            src={Video}
            autoPlay={true}
            muted={true}
            controls={false}
            loop={true}
         />
         <div className='absolute flex flex-col items-center space-y-2 leading-tight'>
            <h1 className='title text-6xl font-black leading-tight'>
               Mežavīrs
            </h1>
            <span className='h-2 w-10 rounded-md bg-gradient-to-r from-emerald-700 to-emerald-600'></span>
            <p className='title pt-1 text-xl font-semibold leading-tight'>
               Apakšvirsraks vai kompānijas motto
            </p>
         </div>
      </div>
   );
};

export default Hero;
