import React, { useState } from 'react';
import { TbHome, TbMail, TbPhone } from 'react-icons/tb';

const Input = ({ title, onChange, value, placeholder }) => {
   return (
      <div className='flex w-full flex-col'>
         <span className='text w-full pl-1 text-sm text-gray-500'>{title}</span>
         <input
            className='text w-full max-w-[450px] bg-gray-200 p-2 text-gray-900 placeholder:text-gray-600 focus:outline-emerald-700'
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
         />
      </div>
   );
};

const Textarea = ({ title, onChange, value, placeholder }) => {
   return (
      <div className='flex w-full flex-col'>
         <span className='text w-full pl-1 text-sm text-gray-500'>{title}</span>
         <textarea
            className='text min-h-[200px] w-full max-w-[450px] bg-gray-200 p-2 text-gray-900 placeholder:text-gray-600 focus:outline-emerald-700'
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
         />
      </div>
   );
};
const Contacts = () => {
   const [email, setEmail] = useState('');
   const [subject, setSubject] = useState('');
   const [message, setMessage] = useState('');
   return (
      <div
         id='kontakti'
         className='flex flex-col items-center bg-gray-100 py-16'
      >
         <div className='flex w-full max-w-screen-lg flex-col px-5 lg:px-0'>
            <h1 className='title pb-2 text-4xl font-black text-gray-900'>
               Kontakti
            </h1>
            <span className='my-2 h-2 w-10 rounded-md bg-gradient-to-r from-emerald-700 to-emerald-600'></span>
            <div className='flex w-full flex-col-reverse gap-2 md:flex-row'>
               <div className='flex w-[100%] flex-col space-y-2 md:w-[50%]'>
                  <h2 className='title py-2 text-xl font-semibold text-gray-500'>
                     Kontaktforma
                  </h2>
                  <Input
                     value={email}
                     title='Jūsu e-pasts'
                     onChange={(value) => setEmail(value)}
                  />
                  <Input
                     value={subject}
                     title='Tēma'
                     onChange={(value) => setSubject(value)}
                  />
                  <Textarea
                     value={message}
                     title='Ziņa'
                     onChange={(value) => setMessage(value)}
                  />
                  <div className='h-3'></div>
                  <button className='text w-full max-w-[450px] bg-gradient-to-r from-emerald-700 to-emerald-600 p-2 text-gray-100 shadow-lg'>
                     Sūtīt ziņu
                  </button>
               </div>
               <div className='flex w-full flex-col space-y-2 md:w-[50%]'>
                  <h2 className='title py-2 text-xl font-semibold text-gray-500'>
                     Kontaktinformācija
                  </h2>
                  <a
                     href='tel:+37129333633'
                     className='flex items-center space-x-3 rounded-md p-2 text-gray-900 duration-200 hover:bg-gray-200'
                  >
                     <TbPhone className='icon-dark h-6 min-h-6 w-6 min-w-6 text-xl' />
                     <span className='text text-lg text-gray-900'>
                        +371 29333633
                     </span>
                  </a>
                  <a
                     href='mailto:epasts@mezavirs.lv'
                     className='flex items-center space-x-3 rounded-md p-2 text-gray-900 duration-200 hover:bg-gray-200'
                  >
                     <TbMail className='icon-dark h-6 min-h-6 w-6 min-w-6 text-xl' />
                     <span className='text text-lg text-gray-900'>
                        info@mezavirs.lv
                     </span>
                  </a>
                  <div className='flex cursor-pointer items-center space-x-3 rounded-md p-2 text-gray-900 duration-200 hover:bg-gray-200'>
                     <TbHome className='icon-dark h-6 min-h-6 w-6 min-w-6 text-xl' />
                     <span className='text text-lg text-gray-900'>
                        Ata Kronvalda iela 55, Cēsis, LV-4101
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Contacts;
