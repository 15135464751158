import React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Import the custom icon
import customIcon from '../map-marker.png'; // Adjust the path to your custom icon

// Create a custom icon
let CustomIcon = L.icon({
   iconUrl: customIcon,
   iconSize: [40, 40], // Adjust the size if needed
   iconAnchor: [12, 41],
   popupAnchor: [1, -34],
});

const coordinates = [
   { lat: 57.532, lng: 25.077 },
   { lat: 57.532, lng: 26.049 },
   { lat: 57.029, lng: 24.271 },
   { lat: 57.525, lng: 26.075 },
   { lat: 57.544, lng: 26.03 },
   { lat: 57.509, lng: 26.088 },
   { lat: 57.785, lng: 25.788 },
   { lat: 57.779, lng: 25.804 },
   { lat: 57.785, lng: 25.741 },
   { lat: 57.759, lng: 25.727 },
   { lat: 57.359, lng: 26.693 },
   { lat: 56.555, lng: 26.856 },
   { lat: 56.392, lng: 27.288 },
   { lat: 56.39, lng: 27.635 },
   { lat: 56.429, lng: 26.676 },
   { lat: 56.43, lng: 26.673 },
   { lat: 57.588, lng: 26.085 },
   { lat: 56.739, lng: 27.001 },
   { lat: 56.74, lng: 26.999 },
   { lat: 56.741, lng: 27.001 },
   { lat: 56.238, lng: 26.782 },
   { lat: 57.568, lng: 26.348 },
   { lat: 57.616, lng: 25.564 },
   { lat: 56.915, lng: 25.58 },
   { lat: 56.335, lng: 27.146 },
   { lat: 57.163, lng: 25.591 },
   { lat: 57.462, lng: 25.624 },
   { lat: 57.454, lng: 25.617 },
   { lat: 56.881, lng: 25.042 },
   { lat: 57.321, lng: 25.671 },
   { lat: 57.207, lng: 26.611 },
   { lat: 57.468, lng: 24.954 },
   { lat: 57.191, lng: 25.445 },
   { lat: 57.187, lng: 25.445 },
   { lat: 57.345, lng: 25.624 },
   { lat: 56.26, lng: 26.463 },
   { lat: 57.82, lng: 26.043 },
   { lat: 56.686, lng: 24.001 },
   { lat: 57.318, lng: 25.665 },
   { lat: 56.783, lng: 25.096 },
   { lat: 57.583, lng: 24.827 },
   { lat: 56.667, lng: 27.171 },
   { lat: 56.338, lng: 27.105 },
   { lat: 56.944, lng: 25.071 },
   { lat: 56.089, lng: 26.759 },
   { lat: 56.878, lng: 24.589 },
   { lat: 56.796, lng: 27.473 },
   { lat: 57.456, lng: 24.601 },
   { lat: 57.038, lng: 25.723 },
   { lat: 57.433, lng: 25.173 },
   { lat: 57.857, lng: 25.168 },
   { lat: 56.559, lng: 27.096 },
   { lat: 56.33, lng: 27.259 },
   { lat: 56.554, lng: 26.111 },
   { lat: 57.385, lng: 25.848 },
   { lat: 57.412, lng: 25.72 },
];

const Map = () => {
   return (
      <div
         id='darbu-karte'
         className='relative flex h-auto w-full justify-center bg-gray-200 md:h-[450px]'
      >
         <div className='flex w-full max-w-screen-lg flex-col md:flex-row'>
            <div className='left-0 top-0 flex w-full flex-col px-5 py-16 lg:pl-0'>
               <h1 className='title pb-2 text-4xl font-black text-gray-900'>
                  Darbu karte
               </h1>
               <span className='mb-3 mt-2 h-2 w-10 rounded-md bg-gradient-to-r from-emerald-700 to-emerald-600'></span>
               <p className='text w-full text-justify text-gray-500 lg:max-w-[45ch]'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A
                  aliquid architecto autem cupiditate dolores enim ex inventore
                  labore non totam.
               </p>
               <br />
               <p className='text w-full text-justify text-gray-500 lg:max-w-[45ch]'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Maiores maxime placeat voluptate?
               </p>
            </div>
            <div className='static right-0 top-0 z-10 w-[100%] md:absolute md:w-[50%]'>
               <MapContainer
                  center={[56.8796, 24.6032]}
                  zoom={7}
                  style={{ height: '450px', width: '100%' }}
                  zoomControl={false}
                  attributionControl={false}
               >
                  <TileLayer url='https://api.mapbox.com/styles/v1/henzen/clxllli2300g201pf5ektav32/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGVuemVuIiwiYSI6ImNseGxyZjRvaDAxYncycXF4MGtxMTU0N2MifQ.VbYQ-sTs_Qxy3SSVEDpj2g' />{' '}
                  {coordinates.map((coord, index) => (
                     <Marker
                        key={index}
                        position={[coord.lat, coord.lng]}
                        icon={CustomIcon} // Use the custom icon here
                     ></Marker>
                  ))}
               </MapContainer>
            </div>
         </div>
      </div>
   );
};

export default Map;
