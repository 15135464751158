import React, { useState, useEffect } from 'react';
import Logo from '../logo-1.png';

const Navigation = () => {
   const [hasShadow, setHasShadow] = useState(false);

   useEffect(() => {
      const handleScroll = () => {
         if (window.scrollY > 0) {
            setHasShadow(true);
         } else {
            setHasShadow(false);
         }
      };

      window.addEventListener('scroll', handleScroll);

      // Clean up the event listener on component unmount
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   return (
      <div
         className={`sticky top-0 z-50 flex w-full justify-center bg-gray-100 px-5 py-4 transition-shadow duration-300 md:px-10 ${hasShadow ? 'shadow-lg' : ''}`}
      >
         <nav className='flex w-full max-w-screen-lg items-center justify-center md:justify-between'>
            <a
               href='#start'
               className='logo text-4xl text-black'
            >
               <img
                  className='h-10'
                  src={Logo}
                  alt='Company Logo'
               />
            </a>
            <ul className='hidden items-center space-x-3 sm:flex md:space-x-8'>
               <li>
                  <a
                     className='text font-semibold text-gray-800'
                     href='#par-mums'
                  >
                     Par mums
                  </a>
               </li>
               <span className='h-[1px] w-6 rounded-md bg-black'></span>
               <li>
                  <a
                     className='text font-semibold text-gray-800'
                     href='#darbu-karte'
                  >
                     Karte
                  </a>
               </li>
               <span className='h-[1px] w-6 rounded-md bg-black'></span>
               <li>
                  <a
                     className='text font-semibold text-gray-800'
                     href='#kontakti'
                  >
                     Kontakti
                  </a>
               </li>
            </ul>
         </nav>
      </div>
   );
};

export default Navigation;
