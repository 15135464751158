import React from 'react';

const Footer = () => {
   return (
      <div className='flex flex-col'>
         <div className='flex'></div>
         <div className='text w-full bg-gradient-to-r from-emerald-700 to-emerald-600 py-2 text-center text-sm text-gray-100'>
            © 2024 SIA Mežavīrs. Visas tiesības paturētas
         </div>
      </div>
   );
};

export default Footer;
