import React from 'react';

const About = () => {
   return (
      <div
         id='par-mums'
         className='flex justify-center bg-gray-100 px-5 py-16 lg:px-0'
      >
         <div className='flex w-full max-w-screen-lg flex-col items-center justify-center gap-5 lg:flex-row'>
            <div className='flex w-auto flex-col items-center text-center'>
               <h1 className='title pb-2 text-4xl font-black text-gray-900'>
                  Par mums
               </h1>
               <span className='mb-3 mt-2 h-2 w-10 rounded-md bg-gradient-to-r from-emerald-700 to-emerald-600'></span>
               <p className='text max-w-[60ch] text-justify text-gray-500'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A
                  accusantium autem cumque eum exercitationem ipsam iure, maxime
                  modi natus necessitatibus porro quae rerum, similique unde
                  vitae. Accusantium ad atque blanditiis cumque, deleniti, dicta
                  dolor doloremque ea eligendi iure laboriosam nobis nulla
                  quaerat quasi, repellat. Accusantium ad aut cum, deserunt
                  doloribus exercitationem, fuga ipsum iure nisi quos ratione
                  sit! Aut autem deleniti distinctio illo modi nemo odit omnis
                  pariatur sit, voluptas! Cupiditate ducimus earum eum ex id in
                  perspiciatis sunt tenetur. Praesentium, tempora.
               </p>
               <br />
               <p className='text max-w-[60ch] text-justify text-gray-500'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Beatae culpa eveniet illo itaque nihil omnis quisquam sed
                  ullam vel! Culpa delectus dicta error facilis hic modi
                  molestias, nobis perspiciatis quae qui quisquam, reiciendis
                  sit tempore ut velit vero vitae voluptatum!
               </p>
               <br />
               <p className='text max-w-[60ch] text-justify text-gray-500'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Consectetur consequuntur debitis dicta ex itaque, iure
                  molestias ratione. Asperiores, consectetur, numquam.
               </p>
            </div>
         </div>
      </div>
   );
};

export default About;
