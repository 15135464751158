import React from 'react';
import { TbTrees, TbWood, TbWorld } from 'react-icons/tb';

const Element = ({ icon, text }) => {
   return (
      <div className='flex flex-col items-center space-y-1'>
         <div className='flex h-[130px] w-[130px] items-center justify-center rounded-full bg-emerald-700 bg-opacity-10'>
            <div className='flex h-[100px] w-[100px] items-center justify-center rounded-full bg-emerald-700 bg-opacity-20'>
               <div className='flex h-[60px] w-[60px] items-center justify-center rounded-full bg-emerald-700 bg-opacity-70'>
                  {icon}
               </div>
            </div>
         </div>
         <span className='text text-xl font-bold text-gray-700'>{text}</span>
      </div>
   );
};

const Qualities = () => {
   return (
      <div className='flex w-full justify-center bg-gray-200 py-12'>
         <div className='flex w-full max-w-screen-lg flex-wrap items-center justify-center gap-10 md:gap-0'>
            <Element
               text='Kvalitāte 1'
               icon={<TbTrees className='text-3xl' />}
            />
            <span className='mb-6 ml-3 hidden h-1 w-7 rounded-md bg-emerald-700 md:block'></span>
            <span className='mx-3 mb-6 hidden h-1 w-7 rounded-md bg-emerald-700 md:block'></span>
            <span className='mb-6 mr-3 hidden h-1 w-7 rounded-md bg-emerald-700 md:block'></span>
            <Element
               text='Kvalitāte 2'
               icon={<TbWorld className='text-3xl' />}
            />
            <span className='mb-6 ml-3 hidden h-1 w-7 rounded-md bg-emerald-700 md:block'></span>
            <span className='mx-3 mb-6 hidden h-1 w-7 rounded-md bg-emerald-700 md:block'></span>
            <span className='mb-6 mr-3 hidden h-1 w-7 rounded-md bg-emerald-700 md:block'></span>
            <Element
               text='Kvalitāte 3'
               icon={<TbWood className='text-3xl' />}
            />
         </div>
      </div>
   );
};

export default Qualities;
