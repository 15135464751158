import Navigation from './components/Navigation';
import Hero from './components/Hero';
import About from './components/About';
import Map from './components/Map';
import Footer from './components/Footer';
import Contacts from './components/Contacts';
import Qualities from './components/Qualities';

function App() {
   return (
      <div
         id='start'
         className='flex flex-col bg-gray-100'
      >
         <Navigation />
         <Hero />
         <Qualities />
         <About />
         <Map />
         <Contacts />
         <Footer />
      </div>
   );
}

export default App;
